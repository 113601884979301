@import '~antd/dist/antd.less';

// Custom Ant Design Theme Variables
@primary-color: #136F63; // primary color for all components
@link-color: #BA1111; // link color
@success-color: #136F63; // success state color
@warning-color: #EFAC26; // warning state color
@error-color: #BA1111; // error state color
// @font-size-base: 14px; // major text font size
// @heading-color: rgba(0, 0, 0, 0.85); // heading text color
// @text-color: rgba(0, 0, 0, 0.65); // major text color
// @text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
// @disabled-color: rgba(0, 0, 0, 0.25); // disable state color
// @border-radius-base: 2px; // major border radius
// @border-color-base: #d9d9d9; // major border color
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
@drawer-body-padding: 1rem;
@layout-header-background: #136F63;

.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  min-height: 100vh !important;
  margin: 0;
  padding: 0;
}

.siteHeader,
.siteFooter {
  flex-shrink: 0;
  width: 100%;
}

.siteFooter {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.siteContent {
  flex: 1;
}

.drawerItem:hover {
  background-color: #f8f8f8;
}

.drawerItem:active {
  background-color: #f0f2f5;
}

.formItemGroupField {
  margin: .25rem 0;
}

.requestBetaAccess {
  max-width: 98%;
  width: auto;
  padding: .5rem;
  filter: drop-shadow(0 0 .25rem #136F63);
}

.requestBetaAccess:hover {
  filter: drop-shadow(0 0 .5rem #136F63);
}

/* Responsive Containers */
.framing-container-lg {
  width: 98%;
  max-width: 1200px;
  padding: 1rem 0;
}

.framing-container-md {
  width: 98%;
  max-width: 800px;
  padding: 1rem 0rem;
}

.framing-container-sm {
  width: 98%;
  max-width: 600px;
  padding: 1rem 0rem;
}

.flex-just-center {
  display: flex;
  justify-content: center;
}

.flex-centered-stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


